import React, { useState, Fragment, useEffect } from 'react';

import { Swiper } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useDispatch, useSelector } from 'react-redux';

import Burger from 'react-css-burger';
import { Fade } from 'react-awesome-reveal';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons';
import { isMobile, useMobileOrientation } from 'react-device-detect';

import GetReservation from '../requests/GetReservation';
import { scrollTriggerHeight } from './constants';
import { calendarActions, authActions, uiActions } from '../store/actions';
import { calendarFunctions, commonFunctions } from './helpers';
import Logo from '../assets/tennis_logo_small.png';
import { getDisplayDate, maxDayAmount } from './helpers/calendarFunctions';
import { resetAndClearCache } from './helpers/commonFunctions';
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons';

const Navigation = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    //name of logged-in user
    const name = localStorage.getItem('name');

    const dateSwiper = useSelector((state) => state.calendar.dateSwiper);
    const courtSwiper = useSelector((state) => state.calendar.courtSwiper);
    const activeDaySwiperIndex = useSelector((state) => state.calendar.activeDateSwiperIndex);
    const clubs = useSelector((state) => state.auth.clubs);
    const clubId = useSelector((state) => state.auth.clubId);
    const reservationScrollHeight = useSelector((state) => state.ui.reservationScrollHeight);
    const reservationScrollStartHeight = useSelector(
        (state) => state.ui.reservationScrollStartHeight
    );
    const isLandScape = useSelector((state) => state.ui.isLandScape);
    const isReservationModalOpen = useSelector((state) => state.ui.isReservationModalOpen);
    const title = useSelector((state) => state.ui.reservationsTitle);

    // const token = localStorage.getItem('apiKey');

    const { isLandscape: isL } = useMobileOrientation();

    const history = useHistory();
    const [nav, setNav] = useState(false);
    const [isInstantRes, setIsInstantRes] = useState(false);

    useEffect(() => {
        dispatch(uiActions.setIsLandScape(isL));
        if (isMobile && !commonFunctions.isLaptopOrDesktop() && !isReservationModalOpen) {
        }
        /*
        setTimeout(() => {
            const element = document.getElementById('calendar');
            const topScrollDistance = 0;
            !!element &&
                element.scroll({
                    top: topScrollDistance,
                    behavior: 'smooth',
                });
        }, 500);
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isL]);

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.body.style.overflow = 'hidden';

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function getReservations(swiperCore) {
        const { activeIndex } = swiperCore;
        dispatch(calendarActions.setActiveDateSwiperIndex(activeIndex));
        if (clubId) {
            const { getDateValue, datesList } = calendarFunctions;
            const todayValue = getDateValue(datesList()[dateSwiper?.activeIndex]);
            const tomorrowValue = getDateValue(datesList()[dateSwiper?.activeIndex + 1]);
            if (tomorrowValue) {
                const newReservations = await GetReservation(todayValue, tomorrowValue, clubId);
                dispatch(authActions.setReservations(newReservations));
            }
        }
    }

    function datePicker() {
        if (pathname === '/login') {
            return;
        }
        if (!isLandScape) {
            return;
        }
        if (!isInstantRes) {
            return (
                <div id="dateSelector">
                    {
                        !isInstantRes &&

                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            alt="left"
                            className="dateArrow"
                            color="#246337"
                            onClick={() => {
                                dateSwiper.slideTo(dateSwiper.activeIndex - 1);
                                dispatch(
                                    calendarActions.setActiveDateSwiperIndex(activeDaySwiperIndex - 1)
                                );
                            }}
                        />
                    }

                    <Swiper
                        slidesPerView={1}
                        initialSlide={activeDaySwiperIndex}
                        onSwiper={(s) => dispatch(calendarActions.setDateSwiper(s))}
                        onSlideChange={getReservations}
                    >
                        {calendarFunctions.getDatesForDateSlider()}
                    </Swiper>

                    {
                        !isInstantRes &&

                        <FontAwesomeIcon
                            icon={faArrowCircleRight}
                            alt="right"
                            className="dateArrow"
                            color="#246337"
                            onClick={() => {
                                dateSwiper.slideTo(dateSwiper.activeIndex + 1);
                                dispatch(
                                    calendarActions.setActiveDateSwiperIndex(activeDaySwiperIndex + 1)
                                );
                            }}
                        />
                    }
                </div>
            );
        } else {
            const newDate = new Date();
            return (
                <div
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        margin: '8px 10px 0 10px',
                        whiteSpace: 'nowrap',
                        fontSize: 18 - getDisplayDate(newDate).length * 0.13
                    }}
                >
                    <FontAwesomeIcon
                        icon={faCalendar}
                        alt="calIcon"
                        className="modal-info-icon"
                        color="#5A9591"
                        style={{ marginRight: '4px' }}
                    />
                    <p>{getDisplayDate(newDate)}</p>
                </div>
            );
        }
    }

    function getHeaderStyle() {
        let opacity = 1,
            height = 85;

        if (reservationScrollHeight > reservationScrollStartHeight + scrollTriggerHeight) {
            height -=
                (reservationScrollHeight - reservationScrollStartHeight + scrollTriggerHeight) / 5;
            opacity = height / 85;
            opacity = opacity < 0.1 ? 0 : opacity;
        }
        return {
            opacity,
            height: `${height}px`
        };
    }

    function onLogoClick() {
        if (isInstantRes) {
            return;
        }
        setNav(false);
        dateSwiper && dateSwiper.slideTo(maxDayAmount / 2);
        courtSwiper && courtSwiper.slideTo(0);

        const today = new Date();
        const element = document.getElementById('calendar');
        const topScrollDistance = ((today.getHours() - 7) * 60 * 2 + today.getMinutes() * 2) * 0.97;
        element &&
        element.scroll({
            top: topScrollDistance,
            behavior: 'smooth'
        });
    }

    const handleClubNameClick = () => {
        if (isInstantRes) {
            return;
        }

        resetAndClearCache();
    };

    const handleLogin = () => {
        if (isInstantRes) {
            history.push('/instant-login');
        } else {
            history.push('/login');
        }
        setNav(false);
    };

    const handleLogout = () => {
        setNav(false);
        localStorage.removeItem('user');
        localStorage.removeItem('apiKey');

        // temp solution because i dont have a way reload getcomponent
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };

    const getClubName = () => {
        let name;
        if (clubs && clubs.length) {
            const club = commonFunctions.getCurrentClubInfo(clubs);

            name = club ? club.clubName : ''
        } else {
            name = localStorage.getItem('clubName');
        }

        return name;
    };

    useEffect(() => {
        if (pathname.includes('instant')) {
            setIsInstantRes(true);
        } else {
            setIsInstantRes(false);
        }
    }, [pathname]);

    return (
        <>
            <div id="navbar" style={getHeaderStyle()}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img src={Logo} alt="logo" id="navbarLogo" onClick={onLogoClick} />

                    <div className="navbarNameHolder">
                        <div className="reservationTypeDisplay">
                            {
                                isInstantRes ?
                                    "Intern"
                                    :
                                    "Extern"
                            }
                        </div>
                        {
                            name &&
                            <h3 className="playerName">
                                {name}
                            </h3>
                        }
                    </div>
                </div>
                {isLandScape && pathname !== '/login' && (
                    <div className="header-reservation-container">
                        <p>
                            {getClubName()}
                        </p>
                        <h2
                            style={isInstantRes ? {} : { cursor: 'pointer' }}
                            onClick={handleClubNameClick}
                        >
                            {title}
                        </h2>
                    </div>
                )}
                {datePicker()}
                <Burger
                    onClick={() => setNav(!nav)}
                    active={nav}
                    burger="squeeze"
                    color="#246337"
                    marginTop="0px"
                    style={{ marginRight: '16px' }}
                    aria-label="menu"
                />
            </div>
            <Fade direction="left" duration={300}>
                <div id="navigationContainer" style={!nav ? { display: 'none' } : {}}>
                    <div id="navLeftAlign">

                        <h2
                            className="navigationLinks"
                            onClick={() => {
                                setNav(false);
                                history.push('/');
                            }}
                        >
                            {isInstantRes ? 'Externe Reservierungen' : title}
                        </h2>
                        {!localStorage.getItem('apiKey') ? (
                            <h2
                                className="navigationLinks"
                                onClick={handleLogin}
                            >
                                Login
                            </h2>
                        ) : (
                            <Fragment>
                                <h2
                                    className="navigationLinks"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </h2>
                                <div>{localStorage.getItem('name')}</div>
                            </Fragment>
                        )}
                        {
                            !isInstantRes &&
                            <h2
                                className="navigationLinks"
                                onClick={() => {
                                    setNav(false);
                                    history.push('/clubs');
                                }}
                            >
                                Club wechseln
                            </h2>
                        }
                        <div id="app-version">V{process.env.REACT_APP_VERSION}</div>
                    </div>
                </div>
            </Fade>
        </>
    );
};

export default Navigation;
