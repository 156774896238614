import * as $ from '../actions/actionTypes';

const initialState = {
    reservationScrollHeight: 0,
    reservationScrollStartHeight: 0,
    isLandScape: false,
    isReservationModalOpen: false,
    reservationsTitle: 'Platz reservieren',
};

const uiReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case $.SET_RESERVATION_SCROLL_HEIGHT: {
            return {
                ...state,
                reservationScrollHeight: payload,
            };
        }
        case $.SET_IS_LAND_SCAPE: {
            return {
                ...state,
                isLandScape: payload,
            };
        }
        case $.SET_IS_RESERVATION_MODAL_OPEN: {
            return {
                ...state,
                isReservationModalOpen: payload,
            };
        }
        case $.SET_RESERVATION_SCROLL_START_HEIGHT: {
            return {
                ...state,
                reservationScrollStartHeight: payload,
            };
        }
        case $.SET_RESERVATIONS_TITLE: {
            return {
                ...state,
                reservationsTitle: payload,
            };
        }
        default:
            return state;
    }
};

export default uiReducer;
