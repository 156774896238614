import { isMobile } from 'react-device-detect';

export function isLandScape(orientation, width, height) {
    if (isMobile && orientation) {
        return orientation.type?.includes('landscape');
    }
    // orientation is undefined in IOS looks like...
    else if (isMobile && width > height) {
        return true;
    }
    return false;
}

export function getCurrentClubInfo(clubs, clubName = null) {
    const name = clubName ? clubName : localStorage.getItem('defaultClub');
    return clubs.find((club) => club.shortName === name);
}

export function getCurrentClubInfoBy(clubs, propName, value) {
    return clubs.find((club) => club[propName] === value);
}

export function isLaptopOrDesktop() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return false;
    } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return false;
    }
    return true;
}

export function scaleResolutionNumber(top = 0, left = 0) {
    const fullHDWidth = 1920;
    const fullHDHeight = 1080;

    // const { devicePixelRatio } = window;
    const widthResolution = window.screen.width; // * devicePixelRatio;
    const heightResolution = window.screen.height; // * devicePixelRatio;

    /*
    const widthResolutionRate = widthResolution / fullHDWidth;
    const heightResolutionRate = heightResolution / fullHDHeight;
     */

    if (top) {
        const topRatio = top / fullHDHeight;
        return heightResolution * topRatio;
    }

    if (left) {
        const leftRatio = left / fullHDWidth;
        return widthResolution * leftRatio;
    }
}

export function resetAndClearCache() {
    localStorage.clear();
    window.location.reload(true);
}

export function getUserAge(user) {
    if (user) {
        const currentDate = new Date();
        const { dateOfBirth } = user;
        if (!dateOfBirth) {
            return 100;
        }
        const birthYear = dateOfBirth.substr(0, 4);
        const birthMonth = dateOfBirth.substr(5, 2);
        const birthDay = dateOfBirth.substr(8, 2);
        const birthDate = new Date(birthYear, birthMonth, birthDay);

        const diff = Math.floor(currentDate.getTime() - birthDate.getTime());
        const day = 1000 * 60 * 60 * 24;
        const days = Math.floor(diff / day);
        const months = Math.floor(days / 31);
        return Math.floor(months / 12);
    }
    return 100;
}
