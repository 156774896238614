import React from 'react';
import { Provider } from 'react-redux';

import store from './store';

import Navigation from './components/Navigation';
import Routing from './Routing';
import { useLocation } from 'react-router-dom';

const App = () => {
    const location = useLocation();
    const { pathname } = location;

    function getContainerStyling() {
        if (pathname.includes('boards/')) {
            return {};
        }
        return { height: '100vh', position: 'fixed', top: 0 };
    }

    return (
        <Provider store={store}>
            <div style={getContainerStyling()}>
                {!pathname.includes('boards/') && <Navigation />}
                <Routing />
            </div>
        </Provider>
    );
};

export default App;
