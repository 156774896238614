import { combineReducers } from "redux";

import calendarReducer from "./calendarReducer";
import authReducer from "./authReducer";
import uiReducer from "./uiReducer";

export default combineReducers({
    calendar: calendarReducer,
    auth: authReducer,
    ui: uiReducer,
});
