import * as $ from './actionTypes';

export function setCourtSwiper(courtSwiper) {
    return {
        type: $.SET_COURT_SWIPER,
        payload: courtSwiper,
    };
}

export function setDateSwiper(dateSwiper) {
    return {
        type: $.SET_DATE_SWIPER,
        payload: dateSwiper,
    };
}

export function setActiveDateSwiperIndex(activeIndex) {
    return {
        type: $.SET_ACTIVE_DATE_SWIPER_INDEX,
        payload: activeIndex,
    };
}
