import ValidateToken from "../components/helpers/ValidateToken";
import { apiUrl } from "../components/constants";

const GetReservation = async (startDate, endDate, clubId) => {
    try {
        try {
            ValidateToken();
        } catch (error) {
            localStorage.removeItem("apiKey");
            localStorage.removeItem("name");
        }
        const headers = localStorage.getItem("apiKey")
            ? {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${localStorage.getItem("apiKey")}`,
              }
            : {
                  "Content-Type": "application/json",
                  Accept: "application/json",
              };

        const response = await fetch(
            `${apiUrl}/reservation/getReservations?start=${startDate}&end=${endDate}&clubId=${clubId}`,
            {
                method: "GET",
                headers,
            }
        );

        if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        return [];
    }
};
export default GetReservation;
