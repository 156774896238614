import * as $ from '../actions/actionTypes';
import { maxDayAmount } from '../../components/helpers/calendarFunctions';

const initialState = {
    dateSwiper: null,
    courtSwiper: null,
    activeDateSwiperIndex: maxDayAmount / 2,
};

const calendarReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case $.SET_DATE_SWIPER: {
            return {
                ...state,
                dateSwiper: payload,
            };
        }
        case $.SET_COURT_SWIPER: {
            return {
                ...state,
                courtSwiper: payload,
            };
        }
        case $.SET_ACTIVE_DATE_SWIPER_INDEX: {
            return {
                ...state,
                activeDateSwiperIndex: payload,
            };
        }
        default:
            return state;
    }
};

export default calendarReducer;
