import * as $ from '../actions/actionTypes';

const initialState = {
    user: null,
    clubs: [],
    clubId: null,
    reservations: null,
    showWelcomePopup: false,
};

const authReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case $.SET_CLUBS: {
            return {
                ...state,
                clubs: payload,
            };
        }
        case $.SET_CLUB_ID: {
            return {
                ...state,
                clubId: payload,
            };
        }
        case $.SET_RESERVATIONS: {
            return {
                ...state,
                reservations: payload,
            };
        }
        case $.SET_WELCOME_POPUP: {
            return {
                ...state,
                showWelcomePopup: payload,
            };
        }
        case $.SET_USER_DATA: {
            return {
                ...state,
                user: payload,
            };
        }
        default:
            return state;
    }
};

export default authReducer;
