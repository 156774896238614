import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { days, months } from './date';

export const maxDayAmount = 60;

export function datesList() {
    const dates = [],
        today = new Date();
    for (let d = 0; d <= maxDayAmount; d++) {
        const newDate = new Date();
        newDate.setDate(today.getDate() - maxDayAmount / 2 + d);
        dates.push(newDate);
    }
    return dates;
}

export function getDisplayDate(date) {
    if (date) {
        return `${days[date.getDay()]} ${date.getDate()}. ${months[date.getMonth()]}`;
    }
    return ``;
}

export function getDatesForDateSlider() {
    const dates = [];
    for (let d = 0; d <= maxDayAmount; d++) {
        const newDate = datesList()[d];
        dates.push(
            <SwiperSlide key={d}>
                {({ isActive }) => (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            margin: '8px 10px 0 10px',
                            whiteSpace: 'nowrap',
                            fontSize: 18 - getDisplayDate(newDate).length * 0.13,
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faCalendar}
                            alt="calIcon"
                            className="modal-info-icon"
                            color="#5A9591"
                            style={{ marginRight: '4px' }}
                        />
                        <p>{getDisplayDate(newDate)}</p>
                    </div>
                )}
            </SwiperSlide>
        );
    }
    return dates;
}

export function getDateValue(date) {
    if (date) {
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    }
    return false;
}

export function scrollCalendarToTop() {
    setTimeout(() => {
        const element = document.getElementById('calendar');
        const topScrollDistance = 0;
        element.scroll({
            top: topScrollDistance,
            behavior: 'smooth',
        });
    }, 100);
}
