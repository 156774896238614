import * as $ from './actionTypes';

export function setReservationScrollHeight(height) {
    return {
        type: $.SET_RESERVATION_SCROLL_HEIGHT,
        payload: height,
    };
}

export function setIsLandScape(bool) {
    return {
        type: $.SET_IS_LAND_SCAPE,
        payload: bool,
    };
}

export function setIsReservationModalOpen(bool) {
    return {
        type: $.SET_IS_RESERVATION_MODAL_OPEN,
        payload: bool,
    };
}

export function setReservationScrollStartHeight(height) {
    return {
        type: $.SET_RESERVATION_SCROLL_START_HEIGHT,
        payload: height,
    };
}

export function setReservationsTitle(title) {
    return {
        type: $.SET_RESERVATIONS_TITLE,
        payload: title,
    };
}
