import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

const Login = lazy(() => import('./components/login/Login'));
const PasswordReset = lazy(() => import('./components/login/PasswordReset'));
const NewAccess = lazy(() => import('./components/login/NewAccess'));
const ClubSelector = lazy(() => import('./components/ClubSelector'));
const Calendar = lazy(() => import('./components/Calendar'));
const InfoBoard = lazy(() => import('./components/info-board/InfoBoard'));
const InstantReservationCalendar = lazy(() => import('./components/InstantReservation/InstantReservationCalendar'));
const InstLogin = lazy(() => import('./components/InstantReservation/InstLogin'));

const Routing = () => {
    return (
        <Suspense
            fallback={
                <div id="spinner-container">
                    <ClipLoader size={150} color={'#246337'} loading={true} />
                </div>
            }
        >
            <Switch>
                <Route path="/" exact component={Calendar} />
                <Route path="/login" component={Login} />
                <Route path="/login:clubId" component={Login} />
                <Route path="/resetpassword" component={PasswordReset} />
                <Route path="/newaccess" component={NewAccess} />
                <Route path="/clubs" component={ClubSelector} />
                <Route path="/boards/:clubName" component={InfoBoard} />
                <Route path="/instant/:clubName" component={InstantReservationCalendar}/>
                <Route path='/instant-login' component={InstLogin}/>
                <Route path="/:clubName" component={Calendar} />
            </Switch>
        </Suspense>
    );
};

export default Routing;
