import * as $ from './actionTypes';

export function setClubs(clubs) {
    return {
        type: $.SET_CLUBS,
        payload: clubs,
    };
}

export function setReservations(reservations) {
    return {
        type: $.SET_RESERVATIONS,
        payload: reservations,
    };
}

export function setClubId(clubId) {
    return {
        type: $.SET_CLUB_ID,
        payload: clubId,
    };
}

export function setWelcomePopup(show) {
    return {
        type: $.SET_WELCOME_POPUP,
        payload: show,
    };
}

export function setUserData(data) {
    return {
        type: $.SET_USER_DATA,
        payload: data,
    };
}
