import jwt from 'jsonwebtoken';

const ValidateToken = () => {
  const token = localStorage.getItem('apiKey');
  if (token) {
    const decodedToken = jwt.decode(token);
    const expireDate = new Date(decodedToken.Expiry);
    if (Date.now() > expireDate) {
      localStorage.removeItem('apiKey');
      throw new Error('User not logged in');
    }
  } else {
    throw new Error('User not logged in');
  }
};

export default ValidateToken;
