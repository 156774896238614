export const SET_DATE_SWIPER = 'SET_DATE_SWIPER';
export const SET_ACTIVE_DATE_SWIPER_INDEX = 'SET_ACTIVE_DATE_SWIPER_INDEX';
export const SET_COURT_SWIPER = 'SET_COURT_SWIPER';
export const SET_CLUBS = 'SET_CLUBS';
export const SET_CLUB_ID = 'SET_CLUB_ID';
export const SET_RESERVATIONS = 'SET_RESERVATIONS';

export const SET_USER_DATA = 'SET_USER_DATA';

export const SET_RESERVATION_SCROLL_HEIGHT = 'SET_RESERVATION_SCROLL_HEIGHT';
export const SET_IS_LAND_SCAPE = 'SET_IS_LAND_SCAPE';
export const SET_IS_RESERVATION_MODAL_OPEN = 'SET_IS_RESERVATION_MODAL_OPEN';
export const SET_RESERVATION_SCROLL_START_HEIGHT = 'SET_RESERVATION_SCROLL_START_HEIGHT';
export const SET_WELCOME_POPUP = 'SET_WELCOME_POPUP';
export const SET_RESERVATIONS_TITLE = 'SET_RESERVATIONS_TITLE';
